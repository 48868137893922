import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Login from '../pages/Admin/Login'
import Index from '../pages/Index'
import Member from '../pages/Member'
import Product from '../pages/Product'
import System from '../pages/System'
import Outside from '../pages/Outside'
import Mortgage from '../pages/Mortgage'
import Exam from '../pages/Exam'
import Archive from '../pages/Archive'
import Applet from '../pages/Applet'
import Activity from '../pages/Activity'
import Questions from '../pages/Questions'


const Chart = lazy(()=>import('../pages/Index/Chart'))
const Client = lazy(()=>import('../pages/Member/Client'))
const Detail = lazy(()=>import('../pages/Member/Detail'))
const Proof = lazy(()=>import('../pages/Member/Proof'))
const Calculate = lazy(()=>import('../pages/Member/Calculate'))
const Confirm = lazy(()=>import('../pages/Member/Confirm'))
const Bank = lazy(()=>import('../pages/Product/Bank'))
const ProductList = lazy(()=>import('../pages/Product/Product'))
const Channel = lazy(()=>import('../pages/Product/Channel'))
const Source = lazy(()=>import('../pages/Product/Source'))
const Account = lazy(()=>import('../pages/System/Account'))
const Corp = lazy(()=>import('../pages/System/Corp'))
const City = lazy(()=>import('../pages/System/City'))
const Role = lazy(()=>import('../pages/System/Role'))
const Privilege = lazy(()=>import('../pages/System/Privilege'))
const Employee = lazy(()=>import('../pages/System/Employee'))
const OutsideUser = lazy(()=>import('../pages/Outside/User'))
const OutsideClient = lazy(()=>import('../pages/Outside/Client'))

const MortgageUser = lazy(()=>import('../pages/Mortgage/User'))
const MortgageList = lazy(()=>import('../pages/Mortgage/List'))
const Mortgagesigning = lazy(()=>import('../pages/Mortgage/Signing'))
const MortgageChannel = lazy(()=>import('../pages/Mortgage/Channel'))
const MortgageClient = lazy(()=>import('../pages/Mortgage/Client')) 




const Question = lazy(()=>import('../pages/Exam/Question'))
const Paper = lazy(()=>import('../pages/Exam/Paper'))
const Answer = lazy(()=>import('../pages/Exam/Answer'))
const Userlist = lazy(()=>import('../pages/Archive/Userlist'))
const Integral = lazy(()=>import('../pages/Archive/Integral'))

const Products = lazy(()=>import('../pages/Applet/Product'))
const Test = lazy(()=>import('../pages/Applet/Test'))
const Qrcode = lazy(()=>import('../pages/Applet/Qrcode'))
const YsbUser = lazy(()=>import('../pages/Applet/YsbUser'))
const YsbList = lazy(()=>import('../pages/Applet/YsbList'))
const YsbRebate = lazy(()=>import('../pages/Applet/YsbRebate'))
const YsbPayment = lazy(()=>import('../pages/Applet/YsbPayment'))
const Ad = lazy(()=>import('../pages/Applet/Bhl/Ad'))
const Integrals = lazy(()=>import('../pages/Applet/Bhl/Integral'))
const Allocation = lazy(()=>import('../pages/Applet/Bhl/Allocation'))
const Appcode = lazy(()=>import('../pages/Applet/Bhl/Appcode'))

const Designer = lazy(()=>import('../pages/Applet/Bhl/Designer'))
const House = lazy(()=>import('../pages/Applet/Bhl/House'))
const Recommend = lazy(()=>import('../pages/Applet/Bhl/Recommend'))
const Complain = lazy(()=>import('../pages/Applet/Bhl/Complain'))

const Testdrive = lazy(()=>import('../pages/Activity/Testdrive'))
const TestdriveList = lazy(()=>import('../pages/Activity/List'))

const Type = lazy(()=>import('../pages/Questions/Type'))
const Classify = lazy(()=>import('../pages/Questions/Classify'))
const Problem = lazy(()=>import('../pages/Questions/Problem'))

const routes = [
  {
    path:'/',
    element:<Navigate to="/index"/>
  },
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/index',
    element:<Index/>,
    children:[
      {
        path:'chart',
        element:<Chart/>
      }
    ]
  },

  {
    path:'/member',
    element:<Member/>,
    children:[
      {
        path:'list',
        element:<Client/>
      },
      {
        path:'detail/:from/:id',
        element:<Detail/>
      },
      {
        path:'proof',
        element:<Proof/>
      },
      {
        path:'calculate',
        element:<Calculate/>
      },
      {
        path:'confirm',
        element:<Confirm/>
      }
    ]
  },

  {
    path:'/archive',
    element:<Archive/>,
    children:[
      {
        path:'userlist',
        element:<Userlist/>
      },
      {
        path:'integral',
        element:<Integral/>
      }
    ]
  },

  {
    path:'/outside',
    element:<Outside/>,
    children:[
      {
        path:'client',
        element:<OutsideClient/>
      },
      {
        path:'user',
        element:<OutsideUser/>
      }
    ]
  },

  {
    path:'/product',
    element:<Product/>,
    children:[
      {
        path:'bank',
        element:<Bank/>,
      },
      {
        path:'list',
        element:<ProductList/>
      },
      {
        path:'channel',
        element:<Channel/>
      },
      {
        path:'source',
        element:<Source/>
      }
    ]
  },

  {
    path:'/exam',
    element:<Exam/>,
    children:[
      {
        path:'question',
        element:<Question/>
      },
      {
        path:'paper',
        element:<Paper/>
      },
      {
        path:'answer',
        element:<Answer/>
      }
    ]
  },

  {
    path:'/mortgage',
    element:<Mortgage/>,
    children:[
      {
        path:'user',
        element:<MortgageUser/>
      },
      {
        path:'list',
        element:<MortgageList/>
      },
      {
        path:'signing',
        element:<Mortgagesigning/>
      },
      {
        path:'Channel',
        element:<MortgageChannel/>
      },
      {
        path:'Client',
        element:<MortgageClient/>
      }
    ]
  },


  {
    path:'/applet',
    element:<Applet/>,
    children:[
      {
        path:'product',
        element:<Products/>
      },
      {
        path:'test',
        element:<Test/>
      },
      {
        path:'qrcode',
        element:<Qrcode/>
      },
      {
        path:'ysbuser',
        element:<YsbUser/>
      },
      {
        path:'ysblist',
        element:<YsbList/>
      },
      {
        path:'ysbrebate',
        element:<YsbRebate/>
      },
      {
        path:'ysbpayment',
        element:<YsbPayment/>
      },
      {
        path:'Ad',
        element:<Ad/>
      },
      {
        path:'Integrals',
        element:<Integrals/>
      },
      {
        path:'Allocation',
        element:<Allocation/>
      },
      {
        path:'appcode',
        element:<Appcode/>
      },
      {
        path:'designer',
        element:<Designer/>
      },
      {
        path:'house',
        element: <House/>
      },
      {
        path:'recommend',
        element:<Recommend/>
      },
      {
        path:'complain',
        element: <Complain/>
      }
    ]
  },
 

  {
    path:'/questions',
    element:<Questions/>,
    children:[
      {
        path:'type',
        element:<Type/>
      },
      {
        path:'classify',
        element:<Classify/>
      },
      {
        path:'problem',
        element:<Problem/>
      },
    ]
  },




  {
    path:'/activity',
    element:<Activity/>,
    children:[
      {
        path:'Testdrive',
        element:<Testdrive/>
      },
      {
        path:'list',
        element:<TestdriveList/>
      },
      
    ]
  },

  {
    path:'/system',
    element:<System/>,
    children:[
      {
        path:'account',
        element:<Account/>
      },
      {
        path:'corp',
        element:<Corp/>
      },
      {
        path:'city',
        element:<City/>
      },
      {
        path:'role',
        element:<Role/>
      },
      {
        path:'privilege',
        element:<Privilege/>
      },
      {
        path:'employee',
        element:<Employee/>
      },
    ]
  },
  
 
 
  
  
]

export default routes