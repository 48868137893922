import http from "./http";
const api =  {
  // 登录
  getCaptcha(){
    return http.get('/manage/login/code?str='+Math.random())
  },
  checkCaptcha(param){
    return http.get('/manage/login/checkCode',param)
  },
  accountLogin(param){
    return http.post('/manage/login/login',param)
  },
  //首页
  getStatisticsData(param){
    return http.get('/manage/index/statistics',param)
  },
  getQueueList(param){
    return http.get('/manage/index/queue',param)
  },
  getLineData(param){
    return http.post('/manage/index/week',param)
  },
  getSummaryData(param){
    return http.post('/manage/index/summary',param)
  },
  //系统设置
  getRoleAndCorp(){
    return http.get('/manage/admin/corp')
  },
  getAccountList(){
    return http.get('/manage/admin/list')
  },
  addAdminAccount(param){
    return http.post('/manage/admin/add',param)
  },
  editAdminPassword(param){
    return http.post('/manage/admin/editPwd',param)
  },
  delAdminAccount(id){
    return http.post('/manage/admin/del',{id})
  },
  addSystemArea(param){
    return http.post('/manage/area/addArea',param)
  },
  getSystemArea(pid){
    return http.get('/manage/area/areaList',{pid})
  },
  
  getSystemAreas(param){
    return http.get('/manage/area/areaLists',param)
  },


  getTreeArea(param){
    return http.get('/manage/area/getTreeArea',param)
  },
  updateSystemArea(param){
    return http.post('/manage/area/updateArea',param)
  },
  delSystemArea(id){
    return http.delete(`/manage/area/delArea/${id}`)
  },
  getCorpType(){
    return http.get('/manage/corp/type')
  },
  getCorpList(param){
    return http.get('/manage/corp/list',param)
  },
  getCorpSelect(param){
    return http.get('/manage/corp/getCorp',param)
  },
  addSystemCorp(param){
    return http.post('/manage/corp/add',param)
  },
  delSystemCorp(id){
    return http.post('/manage/corp/del',{id})
  },
  getCorpLists(param){
    return http.get('/manage/corp/agency',param)
  },
  getSystemPrivilege(param){
    return http.get('/manage/privilege/list',param)
  },
  addSystemPrivilege(param){
    return http.post('/manage/privilege/add',param)
  },
  delSystemPrivilege(id){
    return http.get('/manage/privilege/del',{id})
  },
  getMyIcon(){
    return http.get('/manage/privilege/icon')
  },
  getMenus(param){
    return http.get('/manage/privilege/menus',param)
  },
  getRoleList(){
    return http.get('/manage/role/list')
  },
  addSystemRole(param){
    return http.post('/manage/role/add',param)
  },
  delSystemRole(id){
    return http.get('/manage/role/del',{id})
  },
  //银行、产品
  addBankInfo(param){
    return http.post('/manage/bank/add',param)
  },
  getBankList(pid){
    return http.get('/manage/bank/list',{pid})
  },
  delBankInfo(id){
    return http.get('/manage/bank/del',{id})
  },
  
  getSignList(){
    return http.get('/manage/bank/sign')
  },
  addProductInfo(param){
    return http.post('/manage/product/add',param)
  },
  getProductList(param){
    return http.post('/manage/product/list',param)
  },
  delProductInfo(id){
    return http.get('/manage/product/del',{id})
  },
  addChannelInfo(param){
    return http.post('/manage/channel/add',param)
  },
  getChannelList(param){
    return http.get('/manage/channel/list',param)
  },
  getCorpUserTree(param){
    return http.get('/manage/channel/userTree',param)
  },
  delChannelInfo(id){
    return http.get('/manage/channel/del',{id})
  },
  getCancelAssociation(param){
    return http.get('/manage/channel/cancelAssociation',param)
  },
  getUserList(param){
    return http.get('/manage/user/list',param)
  },
  addUserInfo(param){
    return http.post('/manage/user/add',param)
  },
  delUserInfo(id){
    return http.post('/manage/user/del',{id})
  },

  


  //客户中心
  getClientList(param){
    return http.post('/manage/client/list',param)
  },
  getClientDetail(param){
    return http.get('/manage/client/detail',param)
  },
  getLogList(param){
    return http.get('/manage/client/log',param)
  },
  exportCliet(param){
    return http.get('/manage/client/export',param)
  },

  getClientEdit(param){
    return http.get('/manage/client/edit',param)
  },


  getCalculateList(param){
    return http.get('/manage/calculate/list',param)
  },

  addCalculateInfo(param){
    return http.post('/manage/calculate/addCalculate',param)
  },
  searchCalculate(param){
    return http.post('/manage/calculate/searchCalculate',param)
  },
  searchCalculates(param){
    return http.post('/manage/calculate/searchCalculates',param)
  },


  updateRuleDate(param){
    return http.post('/manage/calculate/updateCalculateDate',param)
  },
  updateRestoreDate(id){
    return http.post('/manage/calculate/updateRestoreDate',{id})
  },
  

  updateRuleIdDate(param){
    return http.post('/manage/calculate/updateRuleIdDate',param)
  },
  eliminateClienIdInfo(param){
    return http.post('/manage/calculate/eliminateClienIdInfo',param)
  },

  batchModificationDate(param){
    return http.post('/manage/calculate/batchModification',param)
  },
  updateBatchInfo(param){
    return http.post('/manage/calculate/updateBatchModification',param)
  },
  ClearCalculateTable(){
    return http.get('/manage/calculate/ClearTable')
  },

  temporaryStorageDate(param){
    return http.post('/manage/calculate/temporaryStorage',param)
  },

  getMoneyList(param){
    return http.get('/manage/money/list',param)
  },
  getMoneyProof(param){
    return http.get('/manage/money/proof',param)
  },
  getMoneyConfirm(param){
    return http.get('/manage/money/confirm',param)
  },
  getMoneyConfirmList(param){
    return http.get('/manage/money/confirmList',param)
  },

  addMoneyConfirmInfo(param){
    return http.post('/manage/money/addConfirm',param)
  },
  delMoneyConfirmInfo(id){
    return http.post('/manage/money/delConfirm',{id})
  },

  getMoneyProofDetail(param){
    return http.get('/manage/money/customerInformation',param)
  },
  moneyNoticeInfo(){
    return http.get('/manage/money/notice')
  },
  updateMoneyProof(param){
    return http.post('/manage/money/updateMoneyProof',param)
  },

  SearchForMerchants(param){
    return http.get('/manage/money/searchStore',param)
  },

  getClientSurvey(param){
    return http.get('/manage/survey/get',param)
  },
  addClientSurvey(param){
    return http.post('/manage/survey/add',param)
  },
  //渠道进件
  getOutsideUser(param){
    return http.post('/manage/outside/user',param)
  },
  getOutsideClient(param){
    return http.post('/manage/outside/client',param)
  },
  getOutsideUserListInfo(param)
  {
    return http.get('/manage/outside/userClientList',param)
  },

  //房贷
  getMortgageUser(param){
    return http.post('/manage/mortgage/user',param)
  },
  addMortgageUserInfo(param){
    return http.post('/manage/mortgage/add',param)
  },
  delMortgageUserInfo(id){
    return http.post('/manage/mortgage/del',{id})
  },
  getMortgageList(param){
    return http.post('/manage/mortgage/list',param)
  },
  getMortgageDetail(id){
    return http.get('/manage/mortgage/detail',{id})
  },
  getMortgageLogList(param)
  {
    return http.post('/manage/mortgage/log',param)
  },
  addMortgagelList(param)
  {
    return http.post('/manage/mortgage/adds',param)
  },
  getHouseMoneyList(id){
    return http.get('/manage/mortgage/houseMoney',{id})
  },
  mortgageImport(param)
  {
    return http.post('/manage/mortgage/mortgageImport',param)
  },
  getMortgageData(param){
    return http.get('/manage/mortgage/statistics',param)
  },
  getMortgageChannelList(param)
  {
    return http.post('/manage/mortgage/channel',param)
  },
  addMortgageChannelInfo(param)
  {
    return http.post('/manage/mortgage/channelAdd',param)
  },
  delMortgageChannelInfos({id}){
    return http.post('/manage/mortgage/channelDel',{id})
  },

  getMortgageSignList(param){
    return http.get('/manage/mortgage/signList',param)
  },
  addMortgageSignInfo(param){
    return http.post('/manage/mortgage/signAdd',param)
  },
  delMortgageSignInfo(id){
    return http.post('/manage/mortgage/signDel',{id})
  },

  getMortgageClientList(param){
    return http.get('/manage/mortgage/ClientList',param)
  },
  getTransactionManagerList(param)
  {
    return http.get('/manage/mortgage/transactionManager',param)
  },
  getSignManagerList(param)
  {
    return http.get('/manage/mortgage/signingList',param)
  },
  addClientAddInfo(param)
  {
    return http.post('/manage/mortgage/clientAdd',param)
  },
  delClientAddInfo(id)
  {
    return http.post('/manage/mortgage/clientDel',{id})
  },

  getMortgageLogs(param){
    return http.get('/manage/mortgage/logs',param)
  },







  //意数宝
  getAppletProduct(param)
  {
    return http.post('/manage/applet/product',param)
  },
  addAppletProductInfo(param){
    return http.post('/manage/applet/add',param)
  },
  delAppletProductInfo(id){
    return http.post('/manage/applet/del',{id})
  },
  getAppletCorpQrocdeList(param)
  {
    return http.post('/manage/applet/qrCodeList',param)
  },
  addAppletCorpQrocde(param){
    return http.post('/manage/applet/qrAdd',param)
  },
  delAppletCorpQrocde(id){
    return http.post('/manage/applet/qrDel',{id})
  },
  getAppletYsbList(param)
  {
    return http.post('/manage/applet/ysbList',param)
  },


  //意数宝返佣
  getAppletYsbRebate(param)
  {
    return http.post('/manage/applet/ysbRebate',param)
  },
  addYsbRebateList(param)
  {
    return http.post('/manage/applet/ysbRebateadd',param)
  },


  //意数宝打款
  getAppletYsbPayment(param)
  {
    return http.post('/manage/applet/ysbPayment',param)
  },
  addYsbPayList(param)
  {
    return http.post('/manage/applet/addYsbPayList',param)
  },
  updateYsbPayment(param){
    return http.post('/manage/applet/updateYsbPayment',param)
  },

  getAppletYsbUser(param)
  {
    return http.post('/manage/applet/ysbUser',param)
  },
  getAppeltYsbUserList(param)
  {
    return http.get('/manage/applet/ysbUserInfo',param)
  },
  getAppeltYsbUserListInfo(param)
  {
    return http.get('/manage/applet/ysbUserInvite',param)
  },
  getAppeltYsbUserApplicationList(param)
  {
    return http.get('/manage/applet/ysbUserApplication',param)
  },
  getUpdateYsbUser(param)
  {
    return http.post('/manage/applet/ysbUserUpdate',param)
  },


  getAppeltProductExplainInfo(param)
  {
    return http.get('/manage/applet/ProductExplain',param)
  },



  getAppletBhlIntegralsList(param)
  {
    return http.post('/manage/applet/bhlIntegral',param)
  },

  getAppletBhlIntegralsAdd(param)
  {
    return http.post('/manage/applet/bhlAddIntegral',param)
  },
  getAppletBhlIntegralsDel(id)
  {
    return http.post('/manage/applet/bhlDelIntegral',{id})
  },

  getAppletBhlAdList(param)
  {
    return http.post('/manage/applet/bhlAdList',param)
  },

  getAppletBhuAdAdd(param)
  {
    return http.post('/manage/applet/bhlAdAdd',param)
  },
  getAppletBhuAdDel(id)
  {
    return http.post('/manage/applet/bhlDelAd',{id})
  },

  
  getBhlBusinessCode(param)
  {
    return http.post('/manage/applet/bhlBusinessCode',param)
  },

  AddAppletBhlBusinessocde(param){
    return http.post('/manage/applet/bhlBusinessAdd',param)
  },

  getAppeltDesignerList(param)
  {
    return http.post('/manage/applet/designer',param)
  },

  getAppeltDesignerListStatus(param)
  {
    return http.post('/manage/applet/designerStatus',param)
  },
  getAppeltBhlCustomerListInfo(param)
  {
    return http.get('/manage/applet/bhlCustomerInvite',param)
  },
  updateAppletDesignerDistribution(param)
  {
    return http.post('/manage/applet/distribution',param)
  },
  updateAppletDesigner(param){
    return http.post('/manage/applet/updatedesigner',param)
  },
  getAppeltIntegralList(param)
  {
    return http.post('/manage/applet/integral',param)
  },
  getAppeltInviteList(param)
  {
    return http.post('/manage/applet/invite',param)
  },
  
  getAppeltHouseList(param)
  {
    return http.post('/manage/applet/house',param)
  },
  getAppeltHouseAdd(param)
  {
    return http.post('/manage/applet/houseadd',param)
  },
  getAppletDrainageDecorationList(param)
  {
    return http.post('/manage/applet/decorations',param)
  },
  getAppletDrainageDecorationAdd(param)
  {
    return http.post('/manage/applet/addDecorations',param)
  },
  getAppletDrainageDecorationDel(id)
  {
    return http.post('/manage/applet/DelDecorations',{id})
  },
  getAppletRecommendList(param)
  {
    return http.post('/manage/applet/bhlRecommendList',param)
  },
  addAppletBhlRecommend(param)
  {
    return http.post('/manage/applet/bhlRecommendAdd',param)
  },
  delAppletBhlRecommend(id)
  {
    return http.post('/manage/applet/bhlRecommendDel',{id})
  },


  getAppletComplainList(param)
  {
    return http.post('/manage/applet/bhlComplainList',param)
  },
  
  addAppletBhlComplain(param)
  {
    return http.post('/manage/applet/bhlComplainAdd',param)
  },
  delAppletBhlComplain(id)
  {
    return http.post('/manage/applet/bhlComplainDel',{id})
  },



  getCityLists(param)
  {
    return http.post('/manage/applet/city',param)
  },
  getPackageLists(param)
  {
    return http.post('/manage/applet/packages',param)
  },
  getStylesLists(param)
  {
    return http.post('/manage/applet/decorationStyle',param)
  },
  getFollowUpLogLists(id)
  {
    return http.post('/manage/applet/followuplog',{id})
  },



  getClassifyList(pid){
    return http.get('/manage/questions/list',{pid})
  },
  addClassifyInfo(param){
    return http.post('/manage/questions/add',param)
  },
  delClassifyInfo(id){
    return http.get('/manage/questions/del',{id})
  },
  getProblemList(param){
    return http.post('/manage/questions/problem',param)
  },
  addFaqQuestion(param){
    return http.post('/manage/questions/addProblem',param)
  },
  delFaqQroblemInfo(id){
    return http.get('/manage/questions/delProblem',{id})
  },
  getFaqTypeSelect(param){
    return http.get('/manage/questions/type',param)
  },
  getCategorySelect(param){
    return http.get('/manage/questions/categoryselect',param)
  },
  getCategorySelects(param){
    return http.get('/manage/questions/categorySelects',param)
  },
  getCrmAdminSelect(param){
    return http.get('/manage/questions/adminSelect',param)
  },
  addTypeInfo(param){
    return http.post('/manage/questions/addType',param)
  },
  getTypeList(param){
    return http.post('/manage/questions/typeList',param)
  },
  delFaqTypeInfo(id){
    return http.get('/manage/questions/delType',{id})
  },
  


  //活动板块
  getActivityList(param)
  {
    return http.post('/manage/activitys/list',param)
  },
  addTestDrive(param){
    return http.post('/manage/activitys/addTestDrive',param)
  },
  delTestDrive(id){
    return http.post('/manage/activitys/delTestDrive',{id})
  },
  getActivityDataList(param)
  {
    return http.post('/manage/activitys/activityData',param)
  },
  getAdminList(param){
    return http.get('/manage/activitys/adminUser',param)
  },
  getActivityCategory(param)
  {
    return http.post('/manage/activitys/activityCategory',param)
  },
  getTestdriveListInfo(param)
  {
    return http.get('/manage/activitys/activityCategoryList',param)
  },


  //考试
  addExamData(param){
    return http.post('/manage/exam/add',param)
  },
  getExamList(param){
    return http.get('/manage/exam/list',param)
  },
  delExamInfo(param){
    return http.post('/manage/exam/del',param)
  },
  addExamQuestion(param){
    return http.post('/manage/exam/addQuestion',param)
  },
  getExamQuestion(param){
    return http.get('/manage/exam/question',param)
  },
  delExamQuestion(param){
    return http.post('/manage/exam/delQuestion',param)
  },
  addExamPaper(param){
    return http.post('/manage/exam/addPaper',param)
  },
  getPaperList(param){
    return http.get('/manage/exam/paper',param)
  },
  getPaperAnswerList(param){
    return http.get('/manage/exam/answerList',param)
  },
  getAnswerDetail(param){
    return http.get('/manage/exam/answerDetail',param)
  },
  updateAnswerDetail(param){
    return http.post('/manage/exam/updateAnswer',param)
  },

  //渠道建档
  getArchiveUser(param){
    return http.post('/manage/archive/index',param)
  },
  getArchiveEmployee(){
    return http.get('/manage/archive/employee')
  },
  updateArchiveUser(param){
    return http.post('/manage/archive/updateFiling',param)
  },
  getBatchList(param){
    return http.get('/manage/archive/batch',param)
  },
  getIntegralList(param){
    return http.get('/manage/archive/integral',param)
  },
  getArchiveAccountList(param){
    return http.get('/manage/archive/account',param)
  },
  addIntegralList(param){
    return http.post('/manage/archive/add',param)
  },
  updateArchiveAccountRegister(param){
    return http.post('/manage/archive/updateRegister',param)
  },
  

  
 


  

  //公共部分
  getOutsideStatus(){
    return http.get('/common/outsideStatus')
  },
  getBankTreeData(param){
    return http.get('/common/banktree',param)
  },
  getAreaBankProduct(param){
    return http.get('/common/areatree',param)
  },
  getChannelTree(){
    return http.get('/common/channeltree')
  },
  getStatus(){
    return http.get('/common/status')
  },
  getSignUser(){
    return http.get('/common/signUser')
  },
  addWorkList(param){
    return http.post('/common/wxwork/addWorkList',param)
  },
  getWorkList(param){
    return http.get('/common/wxwork/workList',param)
  }
}
export default api