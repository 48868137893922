import React,{ useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout, Menu} from 'antd';
import Logo from '../../components/Logo'
import Logout from '../../components/Logout'
import api from '../../https/api'
import MyContext from '../../context'
import { crmManageAccount } from '../../utils/utils'
import './index.scss'
const { Header } = Layout

export default function MyHeader() {
  
  const {menuPid,setMenuPid} = useContext(MyContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [current, setCurrent] = useState('')
  const [menus,setMenus] = useState([])
  const [account] = useState(crmManageAccount()) 

  useEffect(()=>{
    const fetchData = async () => {
      if(account.role){
        const { data:{code,data} } = await api.getMenus({pid:0,role:account.role})
        if(code === 403){
          localStorage.removeItem('crm_manage_account')
          return navigate('/login')
        }
        data && data.map(item => {
          if(pathname.includes(item.key)){
            setCurrent(item.key)
            setMenuPid(item.id)
          }
          return item.icon = <i className={`iconfont ${item.icon}`}></i>
        })
        setMenus(data)
      }

    }
    fetchData()
  },[account])

  const getCurrentMenu = (e) => {
    navigate(e.key)
    const menu = menus.find(item => e.key.includes(item.key))
    setCurrent(menu.key)
    if(menuPid !== menu.id){
      setMenuPid(menu.id)
    }
  }
  return (
    <Header className='header'>
      <Logo/>
      <Menu 
        selectedKeys={[current]}
        mode="horizontal" 
        items={menus}
        style={{ fontSize: '18px',flexGrow:1 }}
        onClick={(e)=>getCurrentMenu(e)} 
      />
      <Logout/>
    </Header>
  )
}
